@import 'assets/stylesheet/variables.scss';

 .speechrate_modal .modal-content {
    position: relative;
    // margin: auto; /* 15% from the top and centered */
    padding: 40px 32px 24px;
    width: 100% !important;
    max-width: 100% !important;
    // border: 1px solid #888;
    background: #ffffff;
    border-radius: $theme-border-radius;
    @media screen and (max-width: 1023px) {
      width: 339px;
    //   margin-top: 22vh;
      padding: 51px 18px 24px;
    }
    .submit-final-img {
      width: 110px;
      height: 110px;
      display: block;
      margin: 0px auto;
    }
    h6 {
      margin-top: 20px;
      text-align: center;
      font-family: "Rubik";
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* or 180% */

      letter-spacing: -0.222222px;

      /* Text Grey */

      color: #524e59;
    }
    .img_success {
      width: 110px;
      height: 110px;
      display: block;
      margin: 0px auto;
    }
    .cong_success {
      text-align: center;
      text-transform: uppercase;
      color: #7927c2;
      margin-bottom: 20px;
    }
    .p_success {
      text-align: center;
    }
    .pr_score {
      color: #7927c2;
    }
    .modal-head {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #524e59;
    }
    .modal-subtitle {
      margin-top: 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #524e59;
      mix-blend-mode: normal;
      opacity: 0.98;
    }
    .email-label {
      margin-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      text-transform: uppercase;
      color: #979797;
      margin-bottom: unset;
      &.mob {
        margin-top: 16px;
      }
    }
    .input-email {
      margin-top: 8px;
      width: 339px;
      height: 44px;
      background: #f0eff1;
      border-radius: $theme-border-radius;
      padding: 12px 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.222222px;
      color: #524e59;
      &:focus {
        outline: none;
        box-shadow: none;
        border-radius: $theme-border-radius;
      }
      @media screen and (max-width: 1023px) {
        height: 40px;
        width: 303px;
      }
    }
    .close-modal {
      position: absolute;
      width: 28px;
      height: 28px;
      right: 18px;
      top: 18px;
      cursor: pointer;
      @media screen and (max-width: 1023px) {
        width: 24px;
        height: 24px;
      }
    }
    .modal-submit {
      margin-top: 35px;
      background: #4c1a73;
      border-radius: $theme-border-radius;
      color: #fff;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.888889px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: unset;
      &:hover {
        filter: brightness(75%);
      }
      &.blur {
        background: #e5c4ff;
        &:hover {
          filter: unset;
        }
      }
    }
  }