@import 'assets/stylesheet/variables.scss';

.congratulation-modals .ant-modal-content {
  background-color: white !important;
  padding: 1em !important;
  .ant-select-selection-selected-value {
    padding-top: 0px !important;
  }
}

.congratulation-modals .ant-modal-content{
  text-align: center;
  // display: unset;
  border-top :unset;
}
.congratulation-modals .ant-modal-content .ant-modal-footer{
  border-top :unset;
}

.congratulation-modals .ant-modal-content .ant-modal-close{
  display: unset;
}



.congratulation-text{
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  // color: #7927C2;
  color: #51B77D;
}

.generated-text{
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.864286px;
  color: #524E59;
  margin-top: 10px;
  margin-bottom: 10px;
}
.rc-score-block{
    margin-top: 10px;
    margin-bottom: 10px;
    .rc-score-tag{
      width: 334px;
      height: 28px;

      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      /* identical to box height, or 117% */

      letter-spacing: -0.864286px;
      color: #524E59;
      .rc-score{
        width: 334px;
        height: 28px;

        font-family: Rubik;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 28px;
        /* identical to box height, or 78% */
        letter-spacing: -0.864286px;
        color: #9134D8;
      }
    }
  }

.congratulation-button{
  background-color: rgba(76, 26, 115, 1);
  width: 90%;

  border-radius: $theme-border-radius;
  left: 45%;
  transform: translateX(-50%);
}


@media screen and (min-width: 769px) {
  .congratulation-modals .ant-modal-content{
    width: 560px;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  .congratulation-modals .component-quiz-banner.banner-image {
    background: url("../../assets/image/congratulation.svg");
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
}
