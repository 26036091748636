.header {
  width: 100%;
  height: 40px;
  background: white;
  display: flex;
  padding: 5px 20px;
  justify-content: space-between;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0px;
  z-index: 5;
  img {
    height: 30px;
  }
  button {
    background-color: #9134d8;
    font-size: 10px;
    border: none;
    border-radius: 10px;
    //FREAD-1333
    margin-top: unset !important;
    //FREAD-1333
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #9134d8;
  }
}