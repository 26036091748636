@import 'assets/stylesheet/variables.scss';

.fluency-banner {
  // padding: 20px 40px;
  color: #fff;
  border-radius: $theme-border-radius;
  &.banner-image{
    background:url('../../assets/image/congratulation.svg');
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 768px) {
      background:url('../../assets/image/congratulation.svg');
      background-repeat: no-repeat, no-repeat;
      background-size: 100% 100%;
    }
  }


  .text-container {
    position: relative;
    .description {
        display: inherit;
        font-size: 12px;
    }
    .name {
        // transform: rotate(-3deg);
        display: initial;
        font-size: 20px;
        max-width: 700px;
        font-weight: bold;
        // font-family: COCOGOOSELETTERPRESS;
        margin-bottom: 12px;
        line-height: 1.3;
    }
    .banner-btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
  }
}