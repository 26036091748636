@import 'assets/stylesheet/variables.scss';

.head-teacher {
  height: auto;
  margin-top: 8px !important;
  @media screen and (max-width: 460px) {
    height: unset;
    margin: 1rem 0 !important;
  }

  .teacher-section {
    padding: 1.5rem 15px !important;

    .headTecherIcon {
      width: 16px;
      vertical-align: revert;
      margin-right: 8px;
    }


    .friendMember {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 11px;

      img {
        &.friendPic {
          width: 50px;
          height: 50px;
          border-radius: 100px;
          border: 3px solid white;
          object-fit: cover;
          background: white;
          margin-left: -15px;
        }
      }

    }
    .profile-icons {
      padding-top: 5%;


    }
  }



}

.unit_card{
  background-color: #f7f7f7;
  border-radius: $theme-border-radius;
  box-shadow: 1px 1px 1px;
  padding: 1.5rem 1rem 1rem 1rem;
  position:relative;

  h6{
    font-size: 1.2rem;
  }
}

.unit_lock_unlock_icon{
  // position: absolute;
  // top: 5px;
  // right: 5px;
  cursor:pointer;
}

.unit_list{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;

  h6{
    margin-bottom: 0;
  }
  .unit_lock_unlock_icon{

  }
}

// .card_header{
//   display: flex;
//   p{
//     margin-bottom: 0;
//   }
// }

.guided_path_icon{
    width: 20px !important;
    vertical-align: revert;
    margin-right: 8px;
    height: 20px;
}