@import 'assets/stylesheet/variables.scss';

.toast_container {
  display: flex;
  position: absolute;
  inset: 0;
  align-items: center;
  justify-content: center;
  backdrop-filter: brightness(0.4);
  z-index: 10001;
}

.toast_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  background-color: white;
  border-radius: $theme-border-radius;
  padding: 3rem;
  min-width: 40%;
  min-height: 40%;
  align-items: center;
  justify-content: center;
}

.toast_body p {
  font-size: 1.4rem;
  font-weight: 500;
}
