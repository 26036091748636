@import "assets/stylesheet/variables.scss";

.support-modal {
  font-family: "Rubik";
  height: 90%;
  overflow-y: hidden;
  margin: 10px 0;
  @media screen and (max-width: 460px) {
    overflow-y: hidden;
    height: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-modal {
    top: 160px;
    @media screen and (max-width: 460px) {
      width: 100% !important;
      max-width: unset;
      top: 100px;
    }
  }

  .ant-modal-header {
    display: block;
    background-color: white;
    padding: 25px 15px;
    border: none;
    @media screen and (max-width: 460px) {
      border-bottom: 1px solid #828a9d;
    }

    .ant-modal-title {
      color: #524e59;
      font-size: 28px;
      @media screen and (max-width: 460px) {
        font-size: 24px;
      }
    }
  }

  .ant-modal-content {
    background-color: white !important;
    color: #7d7a83;
    padding-bottom: 7px;
    overflow-y: auto;
    top: -100px;
    height: 81vh;
    border-radius: 7px;
    @media screen and (max-width: 460px) {
      overflow-y: auto;
      height: 85vh;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

    .ant-modal-body {
      padding: 50px 20px 18px 20px !important;
      @media screen and (max-width: 460px) {
        padding: 10px 0 25px 0 !important;
      }

      .container {
        align-items: center;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;

        .heading {
          margin-top: 20px;
          width: 500px;

          h4 {
            font-family: Rubik;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #9134d8;
            margin: 0 0 8px 0;
            margin-left: 30%;
          }

          p {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            margin: unset;
          }
        }

        .contact-details {
          // width: 100%;
          margin-top: 30px;

          .phone,
          .email {
            display: flex;

            svg {
              margin-right: 15px;
            }

            p {
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #524e59;
            }
          }
        }

        .feedback-input {
          width: 100%;
          margin: 15px 0 30px 0;

          .feedback {
            background: #ececec;
            opacity: 0.7;
            border-radius: 8px;
            padding: 15px;
            border: 2px solid transparent;

            &:focus,
            &:hover {
              box-shadow: none;
              border: 2px solid #9351cd;
            }

            &::placeholder {
              font-family: Rubik;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.222222px;
              color: #524e59;
              opacity: 0.4;
            }
          }
        }

        .ant-btn {
          background: #9134d8;
          box-shadow: none;
          border-radius: 20px;
          width: 100%;
          height: 50px;
          border: none;
          font-family: Rubik, serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.888889px;
          color: #ffffff;

          &:hover {
            background-color: #4c1a73;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    background-color: white;
    border: none;
    text-align: left;
    padding: 40px;
    padding-bottom: 60px;
    display: none;
  }

  .ant-modal-close {
    display: inline;

    .anticon {
      padding: 9px;
      background: #ececec;
      border-radius: 100px;
    }
  }

  .ant-modal-close-x {
    position: absolute;
    right: 2px;
    top: 5px;
    @media screen and (max-width: 460px) {
      right: 3px;
    }
  }
}
