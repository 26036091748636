@import 'assets/stylesheet/variables.scss';

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

.cm_card_passage{
    display: block;
    width: 99%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px 10px 40px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px none #000;
    border-radius: $theme-border-radius;
    background-color: #fff;
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.25);

    .cm_para1_note{
        padding-right: 20px;
        font-size: 20px;
        line-height: 150%;
        color: #ffbf49;
        padding-bottom: 20px;
        @media screen and (max-width: $mediaMinWidth){
            text-align: justify;
            padding-right: 0px;
            font-size: 16px;
        }
    }

    .cm_para1_title{
        width: 100%;
        margin-top: 0px;
        margin-bottom: 10px;
        padding-right: 0px;
        padding-left: 0px;
        float: none;
        color: #524e59;
        font-size: 28px;
        line-height: 125%;
        font-weight: 600;
        text-align: left;
        @media screen and (max-width: $mediaMinWidth){
            font-size: 18px;
            line-height: 22px;
        }
    }

    .cm_para1{
        float: none;
        color: #524e59;
        font-size: 20px;
        line-height: 150%;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-right: 20px;
        padding-left: 0px;
        // padding-bottom: 20px;
        @media screen and (max-width: $mediaMinWidth){
            text-align: justify;
            font-size: 16px;
            padding-right: 0px;
        }
    }
    .cm_passage_controls{
        padding-bottom: 10px;
        .cm_passage_buttons{
            display: inline-block;
            // width: auto;
            margin-top: 0px;
            margin-right: 40px;
            // padding : 9px 40px;
            border-style: solid;
            border-width: 2px;
            border-color: #4c1a73;
            border-radius: $theme-border-radius;
            background-color: #fff;
            transition: color 200ms ease, background-color 200ms ease;
            color: #4c1a73;
            font-size: 20px;
            font-weight: 700;
            text-decoration: none;
            width: 10rem;
            height: 3.5rem;
            padding: 0;

            @media screen and (max-width: $mediaMinWidth){
                width: 10rem;
                border-style: solid;
                border-width: 2px;
                border-color: #4c1a73;
                border-radius: $theme-border-radius;
                background-color: #fff;
                transition: color 200ms ease, background-color 200ms ease;
                color: #4c1a73;
                font-weight: 700;
                margin-right: 0px;
                padding-right: 20px;
                padding-left: 20px;
            }
        }

        .cm_passage_buttons:hover{
            background-color: #4c1a73;
            color: #fff;
        }

        .left-button{
            @media screen and (max-width: $mediaMinWidth){
                margin-right: 30px;
            }
        }

        .left-button:disabled{
            border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;
        }

        .right-button:disabled{
            border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;
        }

        @media screen and (max-width: $mediaMinWidth){
            padding-left: 0px;
        }
    }

    .mic-listening{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        .mic-listening-gif{
            height: 5rem;
        }
        .recording-text{
            font-size: 1.4rem;
        }
    }

    .recorded-audio{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .audio_block{
            margin-right: 20px;
            @media screen and (max-width: $mediaMinWidth){
                margin-right: 12px;
            }
            .audio_element{
                @media screen and (max-width: $mediaMinWidth){
                    max-width: 100%;
                }
            }
        }
    }

    .modal {
        display: unset; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    }
    .modal-content {
        position: relative;
        margin: auto; /* 15% from the top and centered */
        margin-top: 25vh;
        padding: 40px 32px 24px;
        width: 403px;
        border: 1px solid #888;
        background: #ffffff;
        border-radius: $theme-border-radius;
        @media screen and (max-width: 1023px) {
          width: 339px;
          margin-top: 22vh;
          padding: 51px 18px 24px;
        }
        .img_success {
          width: 110px;
          height: 110px;
          display: block;
          margin: 0px auto;
        }
        .cong_success {
          text-align: center;
          text-transform: uppercase;
          color: #7927c2;
          margin-bottom: 20px;
        }
        .p_success {
          text-align: center;
        }
        .pr_score {
          color: #7927c2;
        }
        .modal-head {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.3px;
          color: #524e59;
        }
        .modal-subtitle {
          margin-top: 12px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          color: #524e59;
          mix-blend-mode: normal;
          opacity: 0.98;
        }
        .email-label {
          margin-top: 20px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 10px;
          text-transform: uppercase;
          color: #979797;
          margin-bottom: unset;
          &.mob {
            margin-top: 16px;
          }
        }
        .input-email {
          margin-top: 8px;
          width: 339px;
          height: 44px;
          background: #f0eff1;
          border-radius: $theme-border-radius;
          padding: 12px 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.222222px;
          color: #524e59;
          &:focus {
            outline: none;
            box-shadow: none;
            border-radius: $theme-border-radius;
          }
          @media screen and (max-width: 1023px) {
            height: 40px;
            width: 303px;
          }
        }
        .close-modal {
          position: absolute;
          width: 28px;
          height: 28px;
          right: 18px;
          top: 18px;
          cursor: pointer;
          @media screen and (max-width: 1023px) {
            width: 24px;
            height: 24px;
          }
        }
        .modal-submit {
          margin-top: 35px;
          background: #4c1a73;
          border-radius: $theme-border-radius;
          color: #fff;
          text-align: center;
          padding-top: 15px;
          padding-bottom: 16px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 0.888889px;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: unset;
          &:hover {
            filter: brightness(75%);
          }
          &.blur {
            background: #e5c4ff;
            &:hover {
              filter: unset;
            }
          }
        }
    }

    @media screen and (max-width: $mediaMinWidth){
        padding-bottom: 20px;
    }
}


