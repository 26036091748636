@import 'assets/stylesheet/variables.scss';

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

.loader-layout{
    display: block;
    // min-height: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .layout-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;
        text-align: center;
        align-items: center;
        // max-width: 380px;
        max-width: 638px;
        max-height: 668px;
        border-radius: $theme-border-radius;
        background: #FFFFFF;
        box-shadow: 0 0.05rem 0.5rem 0 rgba(0, 0, 0, 0.1),
          0 0.175rem 1.15rem 0 rgba(0, 0, 0, 0.1);
    }

    .rocket{
        width: 416px;
        height: 416px;
        background: url(../../assets/image/rocket-launch.svg);
    }

    // .progress-bar{

    // }

    .loadin-text{
        width: 158px;
        height:28px;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;
        color: #7927C2;
        font-family: Rubik;
    }

    .minute-text{
        font-family: Rubik;
        font-size: 18px;
        line-height: 24px;
        color: #524E59;
        margin-top: 12px;
    }
}