/* Modal.scss */
.epub-content {
  overflow: hidden !important;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-content {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px 70px;
  width: calc(100% - 140px);
  max-height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.navigation-icons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding: 20px 0;
}

.left-icon,
.right-icon {
  cursor: pointer;
  font-size: 30px;
  color:#9134D8;
}

.rectangle-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rectangle {
  border: 2px solid #F1EEF5;
  border-radius: 21.86px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.modal-text-container {
  padding: 36px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-text {
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.record-button,
.end-button,
.try-again-button {
  margin: 0 10px;
  width:271px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #9134D8;
  border:#1E7BE2 solid 2px;
  border-radius: 16px;
  color:white;
  position: absolute;
  bottom:10px;
}

.left-nav{
position: absolute;
left: 20%;
}

.right-nav{
position: absolute;
right: 20%;
}

@media screen and (max-width: 768px) {
  .modal-content {
    padding: 40px; /* Adjust padding for smaller screens */
    width: calc(100% - 40px); /* Adjust width for smaller screens */
    max-height: 80%; /* Adjust max-height for smaller screens */

  }

  .modal-text {
    font-size: 18px; /* Adjust font size for smaller screens */
    line-height: 26px; /* Adjust line height for smaller screens */
  }

  .start-button,
  .try-again-button {
    width: 100%; /* Make buttons full width on smaller screens */
  }
  .image-container{
    padding: 10px;
  }
}

.nav-icon{
  position: absolute;
  top:50%;
  padding: 4px;
}
