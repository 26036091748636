@import 'assets/stylesheet/variables.scss';

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;
.text-instructions-layout {
    background-color: #fff;
    font-family: Inter, sans-serif;
    height : calc(100vh - 65px);
    position: fixed;
    bottom: 0;
    overflow-y: auto;
    width: 100%;
    padding-bottom: 20px;
    // margin-top: 40px;
    .cm_instructions_container{
        margin-left: 9%;
        margin-right: 9%;
        padding-top: 40px;
        padding-bottom: 0px;
        .cm_title_container{
            font-family: Inter, sans-serif;
            .cm_para1_title{
                width: 100%;
                margin-top: 0px;
                margin-bottom: 10px;
                padding-right: 0px;
                padding-left: 0px;
                float: none;
                color: #524e59;
                font-size: 28px;
                line-height: 125%;
                font-weight: 600;
                text-align: left;
                @media screen and (max-width: $mediaMinWidth){
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            .cm_para1{
                margin-top: 0px;
                margin-bottom: 0px;
                padding-right: 20px;
                padding-bottom: 0px;
                padding-left: 0px;
                float: none;
                color: #524e59;
                font-size: 20px;
                line-height: 125%;
                text-align: left;
                @media screen and (max-width: $mediaMinWidth){
                    padding-right: 0px;
                    padding-bottom: 20px;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .cm_card_instruction{
            display: flex;
            width: 100%;
            height: auto;
            margin-top: 0px;
            margin-bottom: 0px;
            padding-top: 0px;
            padding-right: 10px;
            padding-bottom: 0px;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            border: 1px none #000;
            border-radius: $theme-border-radius;
            background-color: transparent;
        }

        .div-block-40{
            text-align: center;
            width: 100%;
            .cm_section_button{
                display: inline-block;
                // width: auto;
                margin-top: 0px;
                padding : 9px 40px;
                border-style: solid;
                border-width: 2px;
                border-color: #4c1a73;
                border-radius: $theme-border-radius;
                background-color: #fff;
                transition: color 200ms ease, background-color 200ms ease;
                color: #4c1a73;
                font-size: 20px;
                font-weight: 700;
                text-decoration: none;

                @media screen and (max-width: $mediaMinWidth){
                    margin-left: unset;
                    margin-right: unset;

                }
            }
            .cm_section_button:hover{
                background-color: #4c1a73;
                color: #fff;
            }
        }

        @media screen and (max-width: $mediaMinWidth){
            padding : 20px 18px 20px 18px;
            margin-left: unset;
            margin-right: unset;

        }
    }
    &.on-login{
        margin-top: 66px;
    }
}
