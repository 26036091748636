@import 'assets/stylesheet/variables.scss';

.meaning_modal_container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: white;
    border-radius: $theme-border-radius;
    flex-direction: column;
    gap: 0.7rem;

}

.meaning_modal_header{
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    width: 100%;

    p{
        margin: 0;
    }

    .close_icon{
        width: 1.5rem;
        height: 1.5rem;
        font-weight: 600;
        cursor: pointer;
    }
}


.meaning_container{
    display: flex;
    gap: 5px;
    width: 100%;
    // flex-wrap: wrap;
    word-break: break-all;
    font-size: 16px;
    font-weight: 450;
}

.meaning_key{
    min-width: max-content;
}

.meaning_value{
    margin: 0;
}