$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@import "assets/stylesheet/variables.scss";

.ceect-cmf-report-container {
  .bg {
    overflow-y: hidden;
  }
  .ceect-bannerImageContainer {
    display: block;
    margin: auto;
    border-radius: 32px;
    // width: 70%;
    // margin-left: 18%;
    margin-top: 5%;
    height: 150px;
    object-fit: contain;

    @media screen and (max-width: 460px) {
      border-radius: 0px;
      margin-left: -3%;
      width: 112%;
    }

    .bannerImage {
      width: 100%;
      @media screen and (min-width: 460px) {
        border-radius: $theme-border-radius;
      }
    }
  }

  .ceect-bannerImage1Container {
      display: block;
      border-radius: 19px;
      width: 75%;
      margin: auto;
    //   margin-top: 18%;
    height: 100px;
    object-fit: contain;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 460px) {
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: unset;
      width: 100%;
      background-repeat: no-repeat;
      height: 235px;
      margin-top: 68%;
    }

    .ceect-save-report-btn{
        color:white;
        font-size: 1.2rem;
        padding: 1rem;
        border-radius:$theme-border-radius;
        height: fit-content;

        &:hover{
            border: none;
            outline: none;
        }
    }
  }

  .heading1 {
    // margin-left: 8%;
    left: 25.26%;
    right: 64.64%;
    top: 19.55%;
    bottom: 79.32%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    // font-size: 18px;
    // line-height: 28px;
    text-transform: capitalize;
    color: #313131;
    margin-left: 10px;
  }

  .heading2 {
    margin-left: 2%;
    left: 25.26%;
    right: 64.64%;
    top: 19.55%;
    bottom: 79.32%;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
    color: #313131;
  }

  .heading3 {
    margin-left: 1%;
    left: 25.26%;
    right: 64.64%;
    top: 19.55%;
    bottom: 79.32%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 28px;
    text-transform: capitalize;
    color: #313131;
    @media screen and (max-width: 460px) {
      line-break: anywhere;
      letter-spacing: 0px;
    }
  }

  .ceect-hrline {
    margin: 2% 0;
  }

  .ceect-hrline1 {
    // margin: 0% 12% 1% 19%;
    width: 75%;
    margin: 1% auto;
    @media screen and (max-width: 460px) {
      margin: 1% 4% 1% 8%;
    }
  }

  hr {
    @media screen and (max-width: 460px) {
      border-top: 4px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .ceect-reportImage {
    width: 9%;
    // margin-left: 3%;
    margin-top: 2%;
    @media screen and (max-width: 460px) {
      width: 80px;
      object-fit: contain;
      height: 33px;
    }
  }

  tbody {
    text-align: center;
  }

  thead {
    text-align: center;
    background-color: #9134d8;
    color: white;
  }

  th {
    font-family: Rubik;
    font-style: normal;
    font-weight: 400;
  }

  .ceect-bannercontainer {
    position: relative;
    height: fit-content;
    left: 0px;
    // top: 208px;
    width: 100%;
    background: #f4ebfb;
    display: grid;

    @media screen and (max-width: 460px) {
      height: 40em;
      margin-top: 8%;
    }
  }

  .icon1 {
    width: 4%;
    float: left;
    margin-right: 3%;
    border-radius: 5px;
    @media screen and (max-width: 460px) {
      width: 19%;
    }
  }

  .side {
    /* margin-left: 37%; */
    width: 65%;
    @media screen and (max-width: 460px) {
      display: none;
    }
  }

  .side11 {
    // margin-left: 69%;
    width: 65%;
    @media screen and (max-width: 460px) {
      display: none;
    }
  }

  td {
    border-color: none !important;
  }

  .border {
    border: 1px solid black;
    border-radius: 5px !important;
  }

  .ceect-upper {
    width: 75%;
    margin: auto;
  }

  .tableHeading {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #313131;

    @media screen and (max-width: 460px) {
      font-size: 14px;
    }
  }

  .tableSubHeading {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #313131;
    @media screen and (max-width: 460px) {
      font-size: 12px;
    }
  }

  .tableData {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #313131;
  }

  .data1 {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    text-align: center;
    text-transform: capitalize;

    color: #313131;
  }

  .ceect-tablecontainer {
    padding: 3% 0 3% 0;
    width: 100%;
    @media screen and (max-width: 460px) {
      overflow-wrap: anywhere;
    }
  }

  .ceect-paratext {
    float: left;
    width: 90%;
    // white-space: nowrap;
    font-size: 1.15rem;
    line-height: 1.75rem;
    text-align:justify;
    @media screen and (max-width: 460px) {
      width: 63%;
      white-space: normal;
    }
  }

  .ceect-paracontainer {
    width: 75%;
    margin: auto;
    padding-bottom: 2%;
    @media screen and (max-width: 460px) {
      margin-left: 4%;
    }
  }

  .ceect-paracontain {
    width: 75%;
    padding-top: 2%;
    letter-spacing: 2px;
    font-size: 1.4rem;
    margin: auto;
    margin-bottom: 1.5rem;
    @media screen and (max-width: 460px) {
      margin-left: 5% !important;
      padding-top: 5% !important;
    }
  }

  .ceect-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 460px) {
      display: flex;
      flex-wrap: wrap;
      margin-right: -46px;
      margin-left: -65px;
    }

    .ceect-col-2 {
      width: 12.5%;
      position: relative;

      .side11 {
        position: absolute;
        right: 0;
      }
    }
    .ceect-col-9 {
      width: 75%;
    }
  }

  .ceect-uppertext {
    // padding-left: 7%;
    padding-top: 5%;
    // margin-left: 12%;
    @media screen and (max-width: 460px) {
      margin-left: 3% !important;
    }
  }
  .ceect-footer-container {
    display: flex;
    // margin-top: 40px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $mediaMinWidth) {
      flex-direction: column-reverse;
    }
    .footer-logo {
      height: 30px;
      margin: 3% 0% 1% 3%;
      @media screen and (max-width: 460px) {
        height: 30px;
      }
    }

    .footertext {
      margin-left: 68%;
      margin-top: 1%;
      @media screen and (max-width: 460px) {
        margin-left: 0%;
        margin-top: 0%;
        padding-right: 2%;
      }
    }

    .footerpara {
      /* font-family: Inter; */
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: right;
      color: #9134d8;
      margin-top: 11%;
    }

    .footerpara1 {
      /* font-family: Inter; */
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: right;
      color: #8b8b8b;
    }
  }
}

body {
  font-family: Rubik;
  font-style: normal;
  overflow-x: hidden;
  background-color: white !important;
}

.ceect-report-para {
  font-size: 1.2rem;
  line-height: 2.5rem;
  // margin-left: 3%;
  margin-bottom: 10px;
  text-align: justify;
}

.ceect-paraouter {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 2rem;
}

.ceect-icon-container {
  color: $magenta-color;
  background-color: white;
  padding: 0.7rem;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $theme-border-radius;

  .icon1 {
    width: 100%;
    min-width: 30px;
    max-width: 30px;
    height: 25px;
  }
}

.ceect-each-point-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.ceect-what-mean {
  display: flex;
  align-items: center;

  h5 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}
