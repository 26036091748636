@import 'assets/stylesheet/variables.scss';

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

.cm_form_block{
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    transition: opacity 200ms ease;
    .cm_form{
        width: 75%;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 20px;
        background-color: #fff;
        text-align: left;

        //.input-block::before{
        //    content:"* required";
        //    color: red;
        //}

        .cm_para1_title{
            width: 100%;
            margin-top: 0px;
            margin-bottom: 10px;
            padding-right: 0px;
            padding-left: 0px;
            float: none;
            color: #524e59;
            font-size: 16px;
            line-height: 125%;
            font-weight: 600;
            text-align: left;
        }

        .cm_section_button{
            display: inline-block;
            // width: auto;
            margin-top: 0px;
            padding : 9px 40px;
            border-style: solid;
            border-width: 2px;
            border-color: #4c1a73;
            border-radius: $theme-border-radius;
            background-color: #fff;
            transition: color 200ms ease, background-color 200ms ease;
            color: #4c1a73;
            font-size: 30px;
            font-weight: 700;
            text-decoration: none;

            @media screen and (max-width: $mediaMinWidth){
                margin-left: unset;
                margin-right: unset;

            }
        }
        .cm_section_button:hover{
            background-color: #4c1a73;
            color: #fff;
        }

        @media screen and (max-width: $mediaMinWidth){
            width: 100%;
            padding: 20px 0 0 0;
        }
    }
}

.cm_form_block .ant-form .ant-row .ant-col{
    width: 100% !important;
}

.loading_container{
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #524e59/10%;
    backdrop-filter: brightness(0.7);
    // opacity: 0.4;
}