.announcements {
  height: 100%;
  margin-top: 16px !important;
  @media screen and (max-width: 460px) {
    height: auto;
    overflow-y: unset;
    overflow-x: unset;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .gray-btn {
    margin: 8px 6px !important;
  }
  .updateHeader {
    padding: 10px 15px 0 15px !important;
    @media screen and (max-width: 460px) {
      padding: 10px 10px 0 10px !important;
    }
  }
  .announcement-mobile-view {
    background: white;
    //padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.assignment {
  margin-top: 16px !important;
  border : 1px solid #d9d9d9;
  border-radius: 4px;
  margin: 5%;
  background: #efe6ff;
  padding: 3%;
}

.osahan-account-page-left-new  {
  margin-top: 0px !important;
}
// index.scss (or create a separate file for modal styles)

// index.scss (or create a separate file for modal styles)

.modal-content {
  border: none; // Remove border if necessary
  padding: 20px; // Adjust padding as needed
  max-width: 90%; // Set the maximum width of the modal
  margin: auto; // Center the modal horizontally
  border-radius: 10px; // Add border radius if desired

  h2 {
    color: #491a6c; // Heading color
  }

  p {
    font-size: 14px; // Text font size
    color: purple; // Text color
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); // Adjust overlay background color and opacity if needed
}


.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: #491a6c;
  font-size: 30px;
  font-weight: bolder;
}

.assignment-details p {
  margin: 5px 0;
}

.assignment-details ul {
  list-style-type: none;
  padding-left: 0;
}

.assignment-details ul li {
  margin-bottom: 5px;
}

// .ReactModal__Content{
//   top:35% !important;
.announcement-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 0.5rem;
}

.announcement-header img {
  width: 20px; /* Adjust icon size as needed */
  margin-right: 10px;
}

.announcement-title {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  margin-top: 8px;
}

.fEWdzP{
  width:80px !important;
  height:80px !important;
}