@import "assets/stylesheet/variables.scss";
.listen_story_container {
  height: 100%;
  width: 100%;
  display: flex;
  // flex-direction: column;
  max-width: 75%;
  min-height: 85vh;
  justify-content: center;
  gap: 5rem;
  align-items: center;
//   background-color: #f1f1f5;
  padding: 2rem;


  .listen_story_image_container {
    width: 50%;
    height: 80%;

    img {
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: fill;
    }

    @media (max-width:768px) {
        height: 50%;
        width:90%
    }
}

.listen_story_details_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1rem;
    padding: 3rem;
    height: 80%;
    background-color: #f1f1f5;
    box-shadow: 1px 1px 1px #9124de;
    border-radius: $theme-border-radius;
    box-shadow: 10px 10px 30px #d9d0d9;
    // box-shadow: rgba(0, 0, 0, 0.15) 10px 10px 30px;

    @media (max-width:1040px) {
        padding: 1.5rem 2rem;
    }

    @media (max-width:768px) {
        width: 90%;
        height: fit-content;
    }

    p {
        margin-bottom: 0;
    }

    .name {
      font-size: 1.5rem;
      font-weight: 600;
      color: black;
    }

    .description {
      font-size: 1rem;
      font-weight: 450;
      color: black;
      margin-top: 0;
       overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      height: 3rem;
    }
    .author_name {
      color: $magenta-color;
      font-weight: 600;
      span {
        // font-size: 1.2rem;
        font-style: italic;
        color: black;
      }
    }

    audio {
      width: 100%;
      margin-top: 1rem;
    }
  }

  @media (max-width:1300px) {
    max-width: 90%;
  }

  @media (max-width:1040px) {
      max-width: 97%;
  }

  @media (max-width:768px) {
    flex-direction: column;
    overflow-y: auto;
    margin-top: 2rem;
    gap: 1.5rem;
  }

}
