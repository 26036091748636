.task-card {
    border: 2px solid #eaebec;
    border-radius: 6px;
    // width: 30%;
    height: 189px;
    margin: 1rem;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin: 1rem 0.25rem;
    }
  
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 48%;
      margin: 1rem 0.25rem;
    }
  
    @media screen and (min-width: 2560px) {
      width: 23%;
    }
    img {
      width: 50%;
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }
    .blank-img {
      background: #e4e4e4;
      width: 100%;
      height: 100%;
      display: inline-block;
    //   vertical-align: top;
    }
    .task-content {
    //   display: inline-block;
    //   width: 50%;
    //   height: 100%;
      padding: 0.5rem;
    //   position: relative;
    text-align: center;
      .content-type {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        text-transform: uppercase;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 8px;
        border-radius: 4px;
  
        &.activity {
          background: rgba(72, 129, 226, 0.1);
          color: #4881e2;
        }
        &.wordgame {
          background: rgba(244, 113, 43, 0.1);
          color: #4881e2;
        }
        &.story {
          background: rgba(244, 113, 43, 0.1);
          color: #f4712b;
        }
        &.quiz {
          background: rgba(121, 39, 194, 0.1);
          color: #7927c2;
        }
      }
      .task-position {
        margin: 2.25rem 0.5rem 0.35rem;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        /* identical to box height, or 120% */
  
        color: #3f4154;
      }
      .task-desc {
        overflow: hidden;
        white-space: nowrap;
        margin: 0rem 0.5rem;
        font-family: "Rubik";
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-overflow: ellipsis;
        height: 20px;
        width: 95%;
        /* identical to box height, or 125% */
  
        /* Text grey */
  
        color: #524e59;
  
        mix-blend-mode: normal;
        opacity: 0.8;
      }
      .task-complete {
        margin: 0.75rem 0.5rem;
        vertical-align: middle;
        img {
          width: 1rem;
          height: 1rem;
          display: inline-block;
          vertical-align: middle;
          margin: 0rem 0.25rem;
        }
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
  
        letter-spacing: -0.514286px;
        text-transform: uppercase;
  
        color: #52cc4d;
      }
    }
  }
  