@import 'assets/stylesheet/variables.scss';

$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;
.passage-layout {
    display: block;
    background-color: #fff;
    font-family: Inter, sans-serif;
    height : 100vh;
    overflow-y: auto;
    .passage-container{
        margin-left: 9%;
        margin-right: 9%;
        padding-top: 40px;
        padding-bottom: 0px;

        @media screen and (max-width: $mediaMinWidth){
            padding : 20px 15px 20px 15px;
            margin-left: unset;
            margin-right: unset;
        
        }
    }
    &.on-login{
        margin-top: 66px;
    }
}
