@import 'assets/stylesheet/variables.scss';

.audio_player_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  width: 100%;

  .waveform {
        width: 100%;
        height: 50px;
    }

  .audio_player_controls_container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    width: 100%;

    .forward,
    .backward {
      border: none;
      outline: none;
      background-color: transparent;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .play_pause {
      border-radius: 100%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $magenta-color;
      border: none;
      outline: none;

      svg{
        color: white;
      }
    }
  }

  input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  background: #ddd; /* Background color of the range track */
  border-radius: $theme-border-radius;
  outline: none;
  cursor: pointer;
  margin: 0 10px;
  accent-color: $magenta-color;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: $magenta-color; /* Color of the slider thumb */
  border-radius: 50%;
  border: 2px solid $magenta-color; /* Optional border */
}

input[type='range']::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: $magenta-color;
  border-radius: 50%;
  border: 2px solid $magenta-color;
}

  .audio_progress_bar{
    width: 100%;
    position: relative;
    z-index: 4;
  }

  .audio_story_bottom_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 6px;

    .audio_player_volume_bar_container{
        display: flex;
        gap: 6px;
        align-items: center;

        .audio_player_volume_bar{
            accent-color: $magenta-color;
        }
    }

    @media (max-width:450px) {
        flex-direction: column;
    }
  }
}
