@import 'assets/stylesheet/variables.scss';

.ant-select-enabled {
  width: 89% !important;
}

.section .ant-col{
  width: 100% !important;
}

.profile-card {
  text-align: center;
  border-radius: $theme-border-radius;
  background: #FFFFFF;
  box-shadow: 1px 1px 1px 0px #2C0B4A26;
  // height: 22rem;
}

.drop-down{
  width:120px ;
}



.select div span i{
  color:white;
  font-weight: bold;
}

.profile-pic img {
  width: 40%;
  margin-top: 10%;
  margin-bottom: 4%;
}

.profile-details {
  line-height: 1.5;
  margin-bottom: 5%;
}

.teacher-name {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;

}

.school-name {
  margin-top: 3%;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;

}

@media screen and (max-width: 1000px) and (max-height: 800px) {
  .grade-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grade{
    margin-bottom: 5px;

  }
}
@media screen and (max-width: 768px){
  .grade-section {
    flex-direction: row;
  }

  .grade{
    margin-bottom: 5px;
  }
}




.grade-section {
  width: 95%;
  margin: auto;
  padding-top: 3%;
  padding-bottom: 3%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: $theme-border-radius;
  background: #828A9D1A;
  gap: 0.5rem;
  padding: 3%;
}

.grade {
  margin-top: 2%;
  width: 100%;
  height: 24px;
    margin: 0 !important;

  // flex: 1;

  .grade-name {
    font-weight: 700;
  }

}

.edit_icon{
  width: 1.5rem;
  height: 1.5rem;
  color: #9351CD;
}

.section {
  width: 128.79px;
  height: 36.28px;
  top: 14268.52px;
  left: 11900px;
  border-radius: $theme-border-radius;

}

// .ant-select, .ant-select-enabled {
//   width: 50% !important;
// }
.ant-select-selection {
  width: 100% !important;
  background-color: #9351CD;
  font-weight: 500;
  border-radius:$theme-border-radius !important;
}

.ant-input-lg, .ant-input{
  border-radius: $theme-border-radius !important;
}

.ant-select-selection--single .ant-select-selection__rendered {
  color: white;
}

.ant-select-selection__placeholder {
  color: white;
}

.ant-select-selection-selected-value {
  padding-top: 0px !important;
}
/* For responsiveness */
@media (max-width: 600px) {
  .profile-pic img {
    width: 100px;
    /* Adjust the width as needed for smaller screens */
    height: 100px;
    /* Adjust the height as needed for smaller screens */
  }
}

.select ant-select ant-select-enabled {
  width: 100% !important;
}

.ant-select-dropdown-menu-item-selected{
  border-radius: $theme-border-radius !important;
}